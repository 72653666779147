<!-- 二维码报告 -->
<template>
    <div class="qrcode">
        <!-- <el-button @click="qw" style="marin:0 auto" type="primary">下载</el-button> -->
          <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >花名册管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback2">学校列表</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">年级列表</el-breadcrumb-item>
            <el-breadcrumb-item>班级列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left" @click="downloadCode">下载当前年级所有学生二维码</div>
            </div>
            <RosterClassTable/>
        </div>
        
  </div>
</template>

<script>
import RosterClassTable from '@/components/table/rosterClassTable.vue'

import QRCode from 'qrcode'
import { jsPDF } from "jspdf";
import {font} from '@/utils/simkai-normal.js'
import {getStudentList} from '@/api/roster.js'
export default {
    components:{
        RosterClassTable,
    },
    data(){
        return{
            list:[],
            orgName:null,
            grade:null
        }
    },
   created(){
        if(this.$route.query){
            //console.log(this.$route.query)
            this.orgName = this.$route.query.orgName;
            this.grade = this.$route.query.grade;
            this.getStuden(this.$route.query.orgId,this.$route.query.grade);
        }
    },
    methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
        goback2(){
            this.$router.go(-2);
        },
        //生成二维码
        qrcode(qrdata){
            var URL =null
             QRCode.toDataURL(
                qrdata, 
                { errorCorrectionLevel: 'L', margin: 2, width: 128 },
                    (err, url) => {
                    if (err) throw err;
                    URL = url
                }
            );
            return URL
        },
        //下载花名册列表
        downloadCode(){
            var doc = new jsPDF();
            doc.addFileToVFS("MyFont.ttf", font);//第一个参数名为自定义命名
            doc.addFont("MyFont.ttf", "MyFont", "normal");//第二个参数为字体的名字，也是自命名，第三个参数统一为normal
            doc.setFont('MyFont');
            doc.setFontSize(10)
            var arr = this.list;
                var length =  arr.length
                var colum = arr.length/3
                var m= 0
                var o = 55
                var y = 10
                for(var i = 0; i< colum; i++){
                   var x = -10
                        for (var j = 0; j< 3; j++){
                                x = x + 50
                                if (m >= length){
                                    break
                                }
                                doc.text(x, y,arr[m].cardId)
                                doc.addImage(arr[m].coder, 'PNG', x, y, 40, 40);
                                doc.text(x, o,arr[m++].name)
                        }
                        y= y + 66
                        o= o + 66
                        if (m%12 === 0 && m < length){
                                    doc.addPage()
                                    y =10
                                    o =55
                        }
                }
                     
                doc.save(`${this.orgName}${this.grade}年级.pdf`);
               
        },

        //获取学生列表
       getStuden(orgId,grade){
            getStudentList({
                orgId:orgId,
                studentGrade:grade
            }).then(res=>{
                if(res.data.status==200){
                    res.data.data.forEach((item,index)=>{
                    var qrdata= escape(`${item.name}/${item.cardId}/-`).replace(/(%u)(\w{4})/gi, "&#x$2;");
                    var info = `  ${item.studentGrade}年级 (${item.studentClass})班 ${item.name}`;
                    var cardId =`  ${item.cardId}`;
                    var code = this.qrcode(qrdata)
                    this.list.push({
                        coder:code,
                        name:info,
                        cardId:cardId
                    })
                });
                }else{
                     this.$message.error(res.data.message);
                }
            })
       },
    }
}
    
</script>

<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}


// component style 
    //联合搜索框样式
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

    
// 正常嵌套样式写法
.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
  
    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 10px
        .tableTop_left
            box-sizing: border-box
            padding: 0 10px
            height: 34px
            color: #fff
            line-height: 34px
            font-size: 14px
            cursor pointer
            border-radius: 2px
            text-align: center
            background-color: #00cccc
       
            

</style>